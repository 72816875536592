import React from "react"
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import { HiOutlineHashtag } from "react-icons/hi"

const TagsList = ({ tagsGroup }) => {
    if (tagsGroup === undefined || tagsGroup.group.length === 0) {
        return null
    }

    return (
        <div className="mt-3 mb-0">
            <small>
                {tagsGroup.group.map((tag, index) => (
                    <Link
                        to={`/tags/${kebabCase(tag.fieldValue)}/`}
                        key={index}
                        className={`mx-1`}
                    >
                        <div className={`text-xs inline-flex items-center font-bold leading-sm border-none`}>
                            <HiOutlineHashtag />
                            {`${tag.fieldValue}${tag.totalCount > 0 ? " (" + tag.totalCount + ")" : ""}`}
                        </div>
                    </Link>
                ))}
            </small>
        </div>
    )
}

export default TagsList